import React, { useState, useEffect, useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import MediaQuery from "react-responsive";
import axios from "axios";
import attachIcon from "../../images/attach-file.svg";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ClipLoader from "react-spinners/ClipLoader";

import location from "../../images/locatio.svg";
import fb from "../../images/fb.svg";
import mail from "../../images/mail.svg";
import phone from "../../images/phone.svg";
import twitter from "../../images/twitter.svg";
import linkln from "../../images/linkln.svg";
import worldMap from "../../images/world_maps.webp";

const ContactForm = ({ data, locale }) => {
  let Swal;
  if (typeof window !== undefined) {
    Swal = require("sweetalert2");
  }
  const [marketingConsent, setMarketingConsent] = useState("No");
  const [entries, setEntries] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    message: "",
    phone: "",
    contactMethod: "contact",
  });
  const [nameErr, setNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [numberErr, setNumberErr] = useState();
  const [orgErr, setOrgErr] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [imgUpload, setImgUpload] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [country, setCountry] = useState("us");
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  let count = 0;
  const onChangeFile = (e) => {
    var file = e.target.files[0];
    setIsFilePicked(true);
    setImgUpload(file);
  };

  const deleteUploadedFile = () => {
    setIsFilePicked(false);
    setImgUpload("");
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  const processReCaptcha = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        return null;
      }

      const token = await executeRecaptcha("submitForm");
      return token || null;
    } catch (e) {
      return null;
    }
  }, [executeRecaptcha]);

  const handleLinkedInCapture = useCallback(async () => {
    try {
      window.lintrk("track", { conversion_id: 6744426 });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleGtagCapture = useCallback(async () => {
    try {
      if (window.gtag) {
        const callback = () => {};
        window.gtag("event", "conversion", {
          send_to: `${
            process.env.GATSBY_LANG === "en"
              ? "AW-10865368368"
              : process.env.GATSBY_LANG === "sv"
              ? "G-PP1G19T52Y"
              : ""
          }/1vEjCPmRrNwDELC6gb0o`,
          event_callback: callback,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleFormSubmit = async (e) => {
    if (entries.email === "") {
      count = count + 1;
      setEmailErr("*Enter Your Business Email");
    }
    if (entries.email !== "") {
      const mailformat = /^[\p{L}\d+.-]+@[\p{L}\d-]+\.\p{L}+$/u;

      if (entries.email.match(mailformat)) {
        setEmailErr("");
      } else {
        count = count + 1;
        setEmailErr("*Enter Your Valid Business Email");
      }
    }
    if (entries.phone !== "") {
      setNumberErr("");
    }
    if (entries.fullname !== "") {
      var regName = /^[\p{L}\s]+$/u;
      if (!regName.test(entries.fullname)) {
        count = count + 1;
        setNameErr("*Enter your Valid full name");
      } else {
        setNameErr("");
      }
    }

    if (entries.phone === "" || entries.phone.length < 8) {
      count = count + 1;
      setNumberErr("*Enter Correct Phone Number");
    }
    if (entries.fullname === "") {
      count = count + 1;
      setNameErr("*Enter Your Full Name");
    }

    if (entries.organization === "") {
      count = count + 1;
      setOrgErr("*Enter Your Organization Name");
    } else if (entries.organization !== "") {
      setOrgErr("");
    }

    const token = await processReCaptcha();

    if (!token) {
      console.log("reCAPTCHA validation failed");
      return;
    }
    console.log("reCAPTCHA validation successful");
    if (count === 0) {
      const source =
        process.env.GATSBY_LANG === "en"
          ? "opsiocloud.com"
          : process.env.GATSBY_LANG === "sv"
          ? "opsio.se"
          : "opsio.in";
      setNumberErr();
      setNameErr();
      setEmailErr();
      setOrgErr();
      var formdata = new FormData();
      formdata.append(
        "data",
        JSON.stringify({
          ...entries,
          fullName: `${entries.firstName} ${entries.lastName}`,
          token,
          source,
          marketingConsent,
        })
      );
      formdata.append("files.file", imgUpload);
      let requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      try {
        setSubmitLoading(true);
        handleLinkedInCapture();
        handleGtagCapture();
        if (data.careersform) {
          await fetch(
            `${process.env.GATSBY_API_URL}/api/email-careers`,
            requestOptions
          );
        } else {
          await axios.post(
            `${process.env.GATSBY_API_URL}/api/contactforms`,
            formdata,
            {
              "content-type": "multipart/form-data",
            }
          );
          await axios.post(`${process.env.GATSBY_API_URL}/api/email-contact`, {
            ...entries,
            token,
            source,
            marketingConsent,
          });
        }
        Swal?.fire({
          title: data.contactLayout.alertTitle,
          text: data.contactLayout.alertText,
          icon: "success",
          confirmButtonColor: "#0CCB95",
        });
      } catch (e) {
        setIsVisible(false);
      } finally {
        setIsVisible(true);
        setSubmitLoading(false);
      }

      setEntries({
        firstName: "",
        lastName: "",
        email: "",
        organization: "",
        message: "",
        phone: "",
        contactMethod: "",
      });
      setIsFilePicked(false);
    }
    e.preventDefault();
  };

  useEffect(() => {
    axios.get("https://api.db-ip.com/v2/free/self").then((ipData) => {
      if (ipData?.data?.countryCode) {
        setCountry(ipData.data.countryCode.toLowerCase());
      }
    });
  }, [country]);

  return (
    <>
      <div
        className="contact_page"
        style={{ backgroundColor: "#17263F" }}
        id="contact_form"
      >
        <MediaQuery minWidth={1025}>
          <div
            className={`contactForm ${
              data.careersform ? "careerForm" : "global-margin"
            }`}
          >
            <div className="row pt-5">
              <div>
                <h6 className="mb-0 ms-3 taperzoid font-size-16 line-height-20 font-weight-700">
                  {data.contactLayout.information}
                </h6>
              </div>
              {!data.careersform && (
                <div className="col-lg-6 col-md-12 col-12 px-5 py-5 text-center contact-details-color">
                  <div className="contact_from">
                    <div
                      className="block"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="contact-input">
                        <input
                          type="text"
                          className="contactFormInput "
                          aria-label="First Name"
                          value={entries.firstName}
                          placeholder={data.firstName}
                          onChange={(e) =>
                            setEntries({
                              ...entries,
                              firstName: e.target.value,
                            })
                          }
                        />
                        <p className="input-error">{nameErr}</p>
                      </div>
                      <div className="number-input">
                        <input
                          type="text"
                          className="contactFormInput "
                          value={entries.lastName}
                          aria-label="Last Name"
                          placeholder={data.lastName}
                          onChange={(e) =>
                            setEntries({ ...entries, lastName: e.target.value })
                          }
                        />
                        <p className="input-error">{nameErr}</p>
                      </div>
                    </div>

                    <div
                      className="block"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="contact-input">
                        <input
                          type="email"
                          className="contactFormInput "
                          aria-label="Email Address"
                          value={entries.email}
                          placeholder={data.email}
                          onChange={(e) =>
                            setEntries({ ...entries, email: e.target.value })
                          }
                        />
                        <p className="input-error">{emailErr}</p>
                      </div>
                      <div className="number-input">
                        <PhoneInput
                          country={country}
                          value={entries.phone}
                          placeholder={data.phonenumber}
                          onChange={(e) => setEntries({ ...entries, phone: e })}
                          containerClass="intTel"
                          inputClass="intTelInput"
                        />
                        <p className="input-error">{numberErr}</p>
                      </div>
                    </div>
                    {!data.careersform && (
                      <div
                        className="block"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="contact-input">
                          <input
                            type="text"
                            className="contactFormInput "
                            aria-label="Organization Name"
                            value={entries.organization}
                            placeholder={data.orgname}
                            onChange={(e) =>
                              setEntries({
                                ...entries,
                                organization: e.target.value,
                              })
                            }
                          />
                          <br />
                          <p className="input-error">{orgErr}</p>
                        </div>
                        <div className="number-input">
                          <label
                            htmlFor="#select-contact-method"
                            className="d-none"
                          />
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            value={entries.contactMethod}
                            onChange={(e) =>
                              setEntries({
                                ...entries,
                                contactMethod: e.target.value,
                              })
                            }
                            id="select-contact-method"
                          >
                            <option value="contact">
                              {data.contactLayout.contact}
                            </option>
                            <option value="sales">
                              {data.contactLayout.sales}
                            </option>
                            {locale !== "en-IN" && (
                              <option value="support">
                                {data.contactLayout.support}
                              </option>
                            )}
                            <option value="finance">
                              {data.contactLayout.humanResources}
                            </option>
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="block">
                      <textarea
                        className="contactFormText  text-white"
                        value={entries.message}
                        placeholder={data.message}
                        onChange={(e) =>
                          setEntries({ ...entries, message: e.target.value })
                        }
                      />
                    </div>
                    <div className="block">
                      {isFilePicked ? (
                        <div className="d-flex justify-content-between text-white">
                          <p>{`Selected File :${JSON.stringify(
                            imgUpload.name
                          )}`}</p>
                          <span
                            title="Delete File"
                            className="file-delete-btn"
                            onClick={deleteUploadedFile}
                          >
                            &times;
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <label className="contactFormInput input-file open-sans">
                        <img className="mx-2" src={attachIcon} alt="attach" />
                        {data.file}
                        <input
                          type="file"
                          aria-label="Upload File"
                          placeholder={data.file}
                          onChange={onChangeFile}
                        />
                      </label>
                    </div>

                    <div className="my-3">
                      <div className="desktopViewCheckbox">
                        <input
                          type="checkbox"
                          aria-label="Accept Policy"
                          onChange={() => setCheckboxStatus(!checkboxStatus)}
                          checked={checkboxStatus}
                          id="policy"
                          className="m-0"
                        />
                        <label
                          htmlFor="#policy"
                          className="text-start white-color link-text "
                          dangerouslySetInnerHTML={{
                            __html: data.checkboxText1,
                          }}
                        />
                      </div>
                      <div className="desktopViewCheckbox">
                        <input
                          type="checkbox"
                          aria-label="Accept Marketing Consent"
                          checked={marketingConsent === "Yes" ? true : false}
                          onChange={(e) =>
                            setMarketingConsent(e.target.checked ? "Yes" : "No")
                          }
                          id="accept"
                          className="m-0"
                        />
                        <label
                          htmlFor="#accept"
                          className="text-start mt-3 white-color link-text "
                        >
                          {data.checkboxText2}
                        </label>
                      </div>
                    </div>
                    {submitLoading ? (
                      <button className="contactFormBtn disabled">
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          size={35}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </button>
                    ) : (
                      <button
                        disabled={!checkboxStatus}
                        className={`contactFormBtn ${
                          !checkboxStatus ? "disabled" : ""
                        }`}
                        onClick={handleFormSubmit}
                      >
                        {data.btn}
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`${
                  data.careersform ? "" : "col-lg-6 ps-5"
                } col-md-12 col-12`}
              >
                <div className="row">
                  <div className="col-12">
                    <h5 className="white-color contact-location our-location text-uppercase ">
                      {data.contactLayout.officeLocation}
                    </h5>
                  </div>
                  <div className="col-6 d-flex h-100 justify-content-start mb-5 pb-5">
                    <div className="mb-5">
                      <div className="align-items-center d-flex mb-3">
                        <img
                          className="pe-2"
                          src={location}
                          alt="location"
                          loading="lazy"
                        />
                        <label className="white-color font-weight-700 font-size-24 line-height-20 ">
                          {data.contactLayout.sweden}
                        </label>
                      </div>
                      <div>
                        <label className="white-color text-addres open-sans">
                          {data.contactDetail.addressSe}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-5 pb-5">
                    <div className="align-items-center d-flex mb-3">
                      <img className="pe-3" src={location} alt="location" />
                      <label className="white-color font-weight-700 font-size-24 line-height-20 ">
                        {data.contactLayout.india}
                      </label>
                    </div>

                    <div>
                      <label className="white-color text-addres open-sans">
                        {data.contactDetail.addressIn}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 mb-5 pb-5">
                    <div>
                      <label className="white-color contact-location our-location ">
                        {data.contactLayout.phoneNumber}
                      </label>
                    </div>
                    <div className="align-items-center d-flex  mt-3">
                      <img
                        className="pe-3"
                        src={phone}
                        alt="phone"
                        loading="lazy"
                      />
                      <label className="white-color open-sans">
                        {data.contactDetail.phoneNumberSe}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 mb-5 pb-5">
                    <div>
                      <label className="white-color contact-location our-location ">
                        {data.contactLayout.indiaPhoneNumber}
                      </label>
                    </div>
                    <div className="align-items-center d-flex  mt-3">
                      <img className="pe-3" src={phone} alt="phone" />
                      <label className="white-color open-sans">
                        {data.contactDetail.phoneNumberIn}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 mb-5 pb-5">
                    <div>
                      <label className="white-color contact-location our-location ">
                        {data.contactLayout.email}
                      </label>
                    </div>

                    <div className="align-items-center d-flex  mt-3">
                      <img
                        src={mail}
                        alt="mail"
                        className="pe-3"
                        loading="lazy"
                      />
                      <label className="white-color open-sans">
                        Email: {data.contactDetail.emailId}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 mb-5 pb-5">
                    <div>
                      <label className="white-color contact-location our-location  ">
                        {data.contactLayout.socialMedia}
                      </label>
                    </div>

                    <div className="align-items-center d-flex mt-3">
                      <div>
                        <a
                          href={data.contactDetail.linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className=""
                            src={linkln}
                            alt="LinkedIn"
                            loading="lazy"
                          />
                        </a>
                      </div>

                      <div>
                        <a
                          href="https://twitter.com/opsiocloud"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="px-4"
                            src={twitter}
                            alt="Twitter"
                            loading="lazy"
                          />
                        </a>
                      </div>

                      <div>
                        <a
                          href="https://www.facebook.com/opsiosverige"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="px-2"
                            src={fb}
                            alt="Facebook"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1024}>
          <div className="pt-4">
            <h6 className="mb-0 ms-3 taperzoid font-size-9  font-weight-400">
              {data.contactLayout.information}
            </h6>
          </div>
          <div className="contactForm containerwrap contact-details-color">
            <div className="row">
              <div className="col-xl-4 col-md-12 col-12">
                <div className="inside_contact">
                  <div className="contact_from">
                    <div className="block d-flex">
                      <div className="contact-input">
                        <input
                          type="text"
                          className="contactFormInput "
                          value={entries.firstName}
                          placeholder={data.firstName}
                          onChange={(e) =>
                            setEntries({
                              ...entries,
                              firstName: e.target.value,
                            })
                          }
                        />
                        <p className="input-error">{nameErr}</p>
                      </div>
                      <div className="number-input">
                        <input
                          type="text"
                          className="contactFormInput "
                          value={entries.lastName}
                          placeholder={data.lastName}
                          onChange={(e) =>
                            setEntries({ ...entries, lastName: e.target.value })
                          }
                        />
                        <p className="input-error">{nameErr}</p>
                      </div>
                    </div>
                    <div
                      className="block"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="email"
                        value={entries.email}
                        className="contactFormInput"
                        placeholder={data.email}
                        onChange={(e) =>
                          setEntries({ ...entries, email: e.target.value })
                        }
                        required
                      />
                      <p className="input-error">{emailErr}</p>
                      <PhoneInput
                        country={"us"}
                        value={entries.phone}
                        placeholder={data.phonenumber}
                        onChange={(e) => setEntries({ ...entries, phone: e })}
                        containerClass="intTel"
                        inputClass="intTelInput"
                        required
                      />
                      <p className="input-error">{numberErr}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-md-12 col-12">
                <div className="contact_from">
                  {!data.careersform && (
                    <div className="block">
                      <div className="contact-input">
                        <input
                          type="text"
                          className="contactFormInput "
                          value={entries.organization}
                          placeholder={data.orgname}
                          onChange={(e) =>
                            setEntries({
                              ...entries,
                              organization: e.target.value,
                            })
                          }
                        />
                        <br />
                        <p className="input-error">{orgErr}</p>
                      </div>
                      <div className="number-input">
                        <label htmlFor="#select-contactMethod" />
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          value={entries.contactMethod}
                          onChange={(e) =>
                            setEntries({
                              ...entries,
                              contactMethod: e.target.value,
                            })
                          }
                          id="select-contact-method"
                        >
                          <option value="contact">
                            {data.contactLayout.contact}
                          </option>
                          <option value="sales">
                            {data.contactLayout.sales}
                          </option>
                          {locale !== "en-IN" && (
                            <option value="support">
                              {data.contactLayout.support}
                            </option>
                          )}
                          <option value="finance">
                            {data.contactLayout.humanResources}
                          </option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="block">
                    <textarea
                      className="contactFormText"
                      value={entries.message}
                      placeholder={data.message}
                      onChange={(e) =>
                        setEntries({ ...entries, message: e.target.value })
                      }
                    />
                  </div>
                  <div className="block">
                    {isFilePicked ? (
                      <div className="d-flex justify-content-between">
                        <p className="selected-file">{`Selected File :${JSON.stringify(
                          imgUpload.name
                        )}`}</p>
                        <span
                          title="Delete File"
                          className="file-delete-btn"
                          onClick={deleteUploadedFile}
                        >
                          &times;
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <label className="contactFormInput input-file">
                      <img className="px-1" src={attachIcon} alt="attach" />

                      {data.file}
                      <input
                        type="file"
                        placeholder={data.file}
                        onChange={onChangeFile}
                      />
                    </label>
                  </div>

                  <div className="mobileViewCheckbox">
                    <input
                      type="checkbox"
                      checked={checkboxStatus}
                      onChange={() => setCheckboxStatus(!checkboxStatus)}
                      id="policy"
                    />
                    <label
                      htmlFor="#policy"
                      className="text-start white-color "
                      dangerouslySetInnerHTML={{ __html: data.checkboxText1 }}
                    />
                  </div>
                  <div className="mobileViewCheckbox">
                    <input
                      type="checkbox"
                      checked={marketingConsent === "Yes" ? true : false}
                      onChange={(e) =>
                        setMarketingConsent(e.target.checked ? "Yes" : "No")
                      }
                      id="policy"
                    />
                    <label
                      htmlFor="#policy"
                      className="text-start white-color "
                    >
                      {data.checkboxText2}
                    </label>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    {submitLoading ? (
                      <button className="contactFormBtn disabled">
                        <ClipLoader
                          color={"#fff"}
                          loading={true}
                          size={35}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </button>
                    ) : (
                      <button
                        disabled={!checkboxStatus}
                        className={`contactFormBtn  ${
                          !checkboxStatus ? "disabled" : ""
                        }`}
                        onClick={handleFormSubmit}
                      >
                        {data.btn}
                      </button>
                    )}
                  </div>

                  <div>
                    <div className="row my-4 pb-5">
                      <h5
                        className={`${
                          isMobileView
                            ? "white-color contact-location ms-3 text-center"
                            : "white-color contact-location ms-3"
                        }`}
                      >
                        {data.contactLayout.officeLocation}
                      </h5>
                      <div
                        className={` ${
                          isMobileView ? "col-12 mb-5" : "col mb-5"
                        }`}
                      >
                        <h4
                          className={`${
                            isMobileView
                              ? "white-color m-0 fs-6 text-center"
                              : "white-color m-0 fs-6 text-start"
                          }`}
                        >
                          {data.contactLayout.sweden}
                        </h4>
                        <h6
                          className={` ${
                            isMobileView
                              ? "white-color m-0 pt-3 ms-1 mobileview-ind-add-tex text-center"
                              : "white-color m-0 pt-3 ms-1 mobileview-ind-add-tex"
                          }`}
                        >
                          {data.contactDetail.addressSe}
                        </h6>
                      </div>
                      <div className="col">
                        <h4
                          className={` ${
                            isMobileView
                              ? "white-color my-2 fs-6 text-start mt-4 text-center"
                              : "white-color my-2 fs-6 text-start mt-4"
                          }`}
                        >
                          {data.contactLayout.india}
                        </h4>
                        <h6
                          className={` ${
                            isMobileView
                              ? "white-color mobileview-ind-add-tex text-center"
                              : "white-color mobileview-ind-add-tex "
                          }`}
                        >
                          {data.contactDetail.addressIn}
                        </h6>
                      </div>
                    </div>
                    <div className="row  pb-5">
                      <div
                        className={` ${
                          isMobileView ? "col-12 text-center" : "col"
                        }`}
                      >
                        <label className="white-color contact-location  mobileview-ind-phone-text">
                          <img className="p-2" src={phone} alt="phone" />
                          {data.contactLayout.phoneNumber}
                        </label>
                        <label className="white-color fs-6 ms-2">
                          {data.contactDetail.phoneNumberSe}
                        </label>
                      </div>
                      <div
                        className={` ${
                          isMobileView ? "col-12 text-center" : "col"
                        }`}
                      >
                        <label className="white-color contact-location  mobileview-ind-phone-text">
                          <img className="p-2" src={phone} alt="phone" />
                          {data.contactLayout.indiaPhoneNumber}
                        </label>
                        <label className="white-color fs-6 ms-2">
                          {data.contactDetail.phoneNumberIn}
                        </label>
                      </div>
                    </div>
                    <div className="row pb-5 ">
                      <div
                        className={` ${
                          isMobileView ? "col-12 text-center" : "col"
                        }`}
                      >
                        <img className="p-2" src={mail} alt="mail" />
                        <label className="white-color contact-location   mobileview-ind-phone-text">
                          {" "}
                          {data.contactLayout.email}{" "}
                        </label>
                        <br></br>
                        <label className="white-color fs-6">
                          {" "}
                          {data.contactDetail.emailId}{" "}
                        </label>
                      </div>
                      <div
                        className={` ${
                          isMobileView ? "col-12 text-center" : "col"
                        }`}
                      >
                        <label className="white-color contact-location   mobileview-ind-phone-text">
                          {data.contactLayout.socialMedia}
                        </label>
                        <br></br>
                        <img className="mx-1" src={linkln} alt="LinkedIn" />
                        <img className="mx-1" src={twitter} alt="Twiiter " />
                        <img className="mx-1" src={fb} alt="Facebook " />
                      </div>
                    </div>
                  </div>

                  {isVisible && (
                    <div
                      className="submit_contact_message"
                      style={{ color: "#0ED39B" }}
                    >
                      {data.contactLayout.thankyou}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <img
          src={worldMap}
          className="contactGlobalImage"
          alt="contactGlobalImage"
        />
      </div>
    </>
  );
};

export default ContactForm;
